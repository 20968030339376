import {FullWidthCarousel} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type FullWidthCarouselContentItem = IContentItem<{
	autoplay: Elements.MultipleChoiceElement
	images: Elements.AssetsElement
}>

export const FullWidthCarouselBlock: Block<FullWidthCarouselContentItem> = ({
	block,
}) => {
	return (
		<div data-kontent-element-codename={block.system.codename}>
			<FullWidthCarousel
				autoplay={block.elements.autoplay[0]?.codename === 'yes'}
			>
				{block.elements.images.map(
					({description, url, width, height}) => (
						<Image
							alt={description || ''}
							className="max-h-screen w-full object-cover"
							height={height || 0}
							key={url}
							loader={loadImageFromKontentAI}
							quality={100}
							src={url}
							width={width || 0}
						/>
					)
				)}
			</FullWidthCarousel>
		</div>
	)
}
